/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { Component, useContext } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import Plx from "react-plx";
import { AppContext } from "~context/AppContext";
import { DocumentContext } from "~context/DocumentContext";
import AppearOnScroll from "~components/AppearOnScroll";
import Footer from "~components/Footer";
import GetInTouch from "~components/GetInTouch";
import Layout from "~components/Layout";
import LocaleLink from "~components/LocaleLink";
import NavClipped from "~components/NavClipped";
import Newsletter from "~components/Newsletter";
import ProjectBlock from "~components/ProjectBlock";
import SEO from "~components/SEO";
import SectionHeading from "~components/SectionHeading";
import Video from "~components/Video.jsx";

class IndexPageComponent extends Component {
  state = {
    bannerLinkVisible: false,
    exploreHoverSecondary: null,
    exploreHoverSource: null,
    masked: true,
    projectHoverIndex: null
  };

  intervals = [];

  timeouts = [];

  componentDidMount() {
    if (window) {
      window.scrollTo(0, 0);
    }

    if (this.props.appContext.viewed) {
      this.setState({
        bannerLinkVisible: true,
        masked: false
      });

      return;
    }

    disableBodyScroll();

    setTimeout(() => {
      const { appContext } = this.props;

      appContext.setNavAnimating(true);
    }, 100);

    this.timeouts.push(
      setTimeout(() => {
        this.setState({
          masked: false
        });

        clearAllBodyScrollLocks();
      }, 3000)
    );

    this.timeouts.push(
      setTimeout(() => {
        const { appContext } = this.props;

        if (appContext.navAnimating) {
          appContext.setNavAnimating(false);
        }

        appContext.setViewed(true);

        this.setState({
          bannerLinkVisible: true
        });
      }, 4000)
    );
  }

  componentWillUnmount() {
    const { appContext } = this.props;

    this.clearTime();

    appContext.setNavAnimating(false);
  }

  clearTime = () => {
    if (this.intervals.length) {
      this.intervals.forEach(interval => {
        clearInterval(interval);
      });
    }

    if (this.timeouts.length) {
      this.timeouts.forEach(timeout => {
        clearTimeout(timeout);
      });
    }
  };

  //

  setHoverSource = (id, lower) => {
    const { frontmatter } = this.props;
    const key = `${id}Hover`;

    let exploreHoverSecondary = false;

    if (lower) {
      exploreHoverSecondary = true;
    }

    this.setState({
      exploreHoverSecondary,
      exploreHoverSource: frontmatter[key]
    });
  };

  //

  render() {
    const {
      appContext,
      documentContext,
      frontmatter,
      location,
      projects
    } = this.props;
    const { viewed } = appContext;
    const { device } = documentContext;

    const {
      bannerLinkVisible,
      exploreHoverSecondary,
      exploreHoverSource,
      masked,
      projectHoverIndex
    } = this.state;

    //

    let titleOpacity =
      1 - documentContext.scrollTop / documentContext.windowHeight;

    if (Number.isNaN(titleOpacity)) {
      titleOpacity = 1;
    } else if (titleOpacity < 0.1) {
      titleOpacity = 0.1;
    }

    //

    const homeProjects = [];

    projects.forEach(project => {
      if (project.frontmatter.showOnHome) {
        homeProjects.push(project);
      }
    });

    homeProjects.sort((a, b) => {
      const priorityA = a.frontmatter.priority;
      const priorityB = b.frontmatter.priority;

      if (priorityA < priorityB) {
        return -1;
      }

      if (priorityA > priorityB) {
        return 1;
      }

      return 0;
    });

    //

    let desktopPoster = ``;

    if (frontmatter?.bannerImage?.childImageSharp?.fluid?.src) {
      desktopPoster = frontmatter.bannerImage.childImageSharp.fluid.src;
    }

    //

    return (
      <>
        <SEO
          customTitle={frontmatter.title}
          customDescription={frontmatter.seoDescription}
          customKeywords={frontmatter.seoKeywords}
          path={location.pathname}
        />

        <Layout
          className="index-page w-full relative overflow-x-hidden"
          location={location}
        >
          {!viewed && (
            <section
              className={`index-page__mask w-screen h-screen fixed top-0 right-0 bottom-0 left-0 z-30 ${
                masked ? `` : `pointer-events-none`
              }`}
              style={{ animationDelay: `2000ms` }}
            ></section>
          )}

          <AppearOnScroll>
            <section className="index-page__banner animation-fade w-full h-screen relative flex items-center justify-center overflow-hidden">
              {(device === `desktop` && (
                <>
                  {(frontmatter?.bannerVideo && (
                    <Video
                      className="absolute block object-cover transform-center"
                      src={frontmatter.bannerVideo}
                      poster={desktopPoster}
                    />
                  )) || (
                    <>
                      {frontmatter.bannerImage && (
                        <Img
                          className="absolute transform-center"
                          fluid={frontmatter.bannerImage.childImageSharp.fluid}
                          alt={frontmatter.bannerImageAlt}
                        />
                      )}
                    </>
                  )}
                </>
              )) || (
                <>
                  {(frontmatter.bannerVideoMobile && (
                    <video
                      className="absolute transform-center"
                      autoPlay
                      loop
                      muted
                      playsInline
                    >
                      <source src={frontmatter.bannerVideoMobile}></source>
                    </video>
                  )) || (
                    <>
                      {frontmatter.bannerImageMobile && (
                        <Img
                          className="absolute transform-center"
                          fluid={
                            frontmatter.bannerImageMobile.childImageSharp.fluid
                          }
                          alt={frontmatter.bannerImageAlt}
                        />
                      )}
                    </>
                  )}
                </>
              )}

              <div
                className="index-page__banner__gradient w-full h-full absolute top-0 right-0 bottom-0 left-0 z-10"
                style={{
                  opacity: 1 - titleOpacity / 2
                }}
              ></div>

              {bannerLinkVisible && (
                <div className="animation-appear w-full absolute bottom-0 left-0 z-20">
                  <LocaleLink
                    to={frontmatter.bannerLinkTarget}
                    className="index-page__link flex justify-end pb-8 xs:pb-8"
                  >
                    <span className="mr-3 b2 feijoa-ot text-white">
                      {frontmatter.bannerLinkText}
                    </span>

                    <span className="project-link-arrow transition-opacity-transform pointer-events-none b1 text-white">
                      →
                    </span>
                  </LocaleLink>
                </div>
              )}
            </section>
          </AppearOnScroll>

          {homeProjects.map((project, index) => {
            const key = project.fields.slug;

            if (!project?.frontmatter?.isPublished) {
              return <React.Fragment key={key} />;
            }

            return (
              <ProjectBlock
                key={key}
                fullHeight
                hovered={projectHoverIndex === index}
                onMouseEnter={() => this.setState({ projectHoverIndex: index })}
                onMouseLeave={() => this.setState({ projectHoverIndex: null })}
                project={project}
              />
            );
          })}

          <section className="index-page__explore w-full relative block pt-12 xs:pt-6 pb-32 xs:pb-24">
            <NavClipped color="black" />

            <SectionHeading padding="" text="Explore" />

            <div className="grid">
              <div className="index-page__explore__links index-page__explore__links--primary grid-end-19 grid-start-4 sm:grid-end-22 sm:grid-start-2 relative">
                <div className="w-full h-full absolute top-0 right-0 bottom left-0 flex items-center justify-center pointer-events-none">
                  {device === `desktop` && exploreHoverSource && (
                    <div
                      className={`index-page__explore__hover ${
                        exploreHoverSecondary ? `secondary` : ``
                      } relative overflow-hidden`}
                    >
                      {(exploreHoverSource.includes(`.mp4`) && (
                        <video
                          className="relative block transform-center"
                          autoPlay
                          loop
                          muted
                          playsInline
                        >
                          <source src={exploreHoverSource}></source>
                        </video>
                      )) || (
                        <img
                          className="relative block transform-center"
                          src={exploreHoverSource}
                          alt="Hover GIF"
                        />
                      )}
                    </div>
                  )}
                </div>

                <ul className="relative mt-6">
                  <li className="index-page__explore__link relative">
                    <LocaleLink
                      to="/studio"
                      className="flex items-center justify-between py-2 xs:py-3"
                      onMouseOver={() => this.setHoverSource(`studio`)}
                      onFocus={() => this.setHoverSource(`studio`)}
                      onMouseOut={() =>
                        this.setState({
                          exploreHoverSource: null
                        })
                      }
                      onBlur={() =>
                        this.setState({
                          exploreHoverSource: null
                        })
                      }
                    >
                      <span className="index-page__explore__link__text transition-opacity f1">
                        Studio
                      </span>
                      <span className="index-page__explore__link__arrow transition-opacity-transform ml-4 pt-1 f2">
                        →
                      </span>
                    </LocaleLink>
                  </li>

                  <li className="index-page__explore__link relative">
                    <LocaleLink
                      to="/services"
                      className="flex items-center justify-between py-2 xs:py-3"
                      onMouseOver={() => this.setHoverSource(`services`)}
                      onFocus={() => this.setHoverSource(`services`)}
                      onMouseOut={() =>
                        this.setState({
                          exploreHoverSource: null
                        })
                      }
                      onBlur={() =>
                        this.setState({
                          exploreHoverSource: null
                        })
                      }
                    >
                      <span className="index-page__explore__link__text transition-opacity f1">
                        Services
                      </span>
                      <span className="index-page__explore__link__arrow transition-opacity-transform ml-4 pt-1 f2">
                        →
                      </span>
                    </LocaleLink>
                  </li>

                  <li className="index-page__explore__link relative">
                    <LocaleLink
                      to="/work"
                      className="flex items-center justify-between py-2 xs:py-3"
                      onMouseOver={() => this.setHoverSource(`work`)}
                      onFocus={() => this.setHoverSource(`work`)}
                      onMouseOut={() =>
                        this.setState({
                          exploreHoverSource: null
                        })
                      }
                      onBlur={() =>
                        this.setState({
                          exploreHoverSource: null
                        })
                      }
                    >
                      <span className="index-page__explore__link__text transition-opacity f1">
                        Work
                      </span>
                      <span className="index-page__explore__link__arrow transition-opacity-transform ml-4 pt-1 f2">
                        →
                      </span>
                    </LocaleLink>
                  </li>

                  <li className="index-page__explore__link relative">
                    <LocaleLink
                      to="/production"
                      className="flex items-center justify-between py-2 xs:py-3"
                      onMouseOver={() => this.setHoverSource(`production`)}
                      onFocus={() => this.setHoverSource(`production`)}
                      onMouseOut={() =>
                        this.setState({
                          exploreHoverSource: null
                        })
                      }
                      onBlur={() =>
                        this.setState({
                          exploreHoverSource: null
                        })
                      }
                    >
                      <span className="index-page__explore__link__text transition-opacity f1">
                        Production
                      </span>
                      <span className="index-page__explore__link__arrow transition-opacity-transform ml-4 pt-1 f2">
                        →
                      </span>
                    </LocaleLink>
                  </li>

                  <li className="index-page__explore__link relative">
                    <LocaleLink
                      to="/contact"
                      className="flex items-center justify-between py-2 xs:py-3"
                      onMouseOver={() => this.setHoverSource(`contact`)}
                      onFocus={() => this.setHoverSource(`contact`)}
                      onMouseOut={() =>
                        this.setState({
                          exploreHoverSource: null
                        })
                      }
                      onBlur={() =>
                        this.setState({
                          exploreHoverSource: null
                        })
                      }
                    >
                      <span className="index-page__explore__link__text transition-opacity f1">
                        Contact
                      </span>
                      <span className="index-page__explore__link__arrow transition-opacity-transform ml-4 pt-1 f2">
                        →
                      </span>
                    </LocaleLink>
                  </li>
                </ul>
              </div>

              {device === `desktop` && (
                <div className="grid-end-19 grid-start-4 py-12">
                  <div className="index-page__explore__line relative block bg-grey"></div>
                </div>
              )}

              <ul className="index-page__explore__links index-page__explore__links--secondary grid-end-19 grid-start-4 sm:grid-end-22 sm:grid-start-2 xs:pt-6">
                <li className="index-page__explore__link relative">
                  <LocaleLink
                    to="/nowthem"
                    className="flex items-center py-3 xs:py-1"
                    onMouseOver={() => this.setHoverSource(`nowThem`, true)}
                    onFocus={() => this.setHoverSource(`nowThem`, true)}
                    onMouseLeave={() =>
                      this.setState({
                        exploreHoverSecondary: false,
                        exploreHoverSource: null
                      })
                    }
                    onBlur={() =>
                      this.setState({
                        exploreHoverSecondary: false,
                        exploreHoverSource: null
                      })
                    }
                  >
                    <span className="index-page__explore__link__text transition-opacity f2">
                      Now&amp;Them
                    </span>
                    <span className="index-page__explore__link__arrow transition-opacity-transform ml-4 pt-1 f2">
                      →
                    </span>
                  </LocaleLink>
                </li>

                <li className="index-page__explore__link relative">
                  <button
                    type="button"
                    className="flex items-center py-3 xs:py-1"
                    onClick={() => {
                      appContext.setNewsActive(true);
                    }}
                    onMouseOver={() => this.setHoverSource(`news`, true)}
                    onFocus={() => this.setHoverSource(`news`, true)}
                    onMouseLeave={() =>
                      this.setState({
                        exploreHoverSecondary: false,
                        exploreHoverSource: null
                      })
                    }
                    onBlur={() =>
                      this.setState({
                        exploreHoverSecondary: false,
                        exploreHoverSource: null
                      })
                    }
                  >
                    <span className="index-page__explore__link__text transition-opacity f2">
                      News
                    </span>
                    <span className="index-page__explore__link__arrow transition-opacity-transform ml-4 pt-1 f2">
                      →
                    </span>
                  </button>
                </li>

                <li className="index-page__explore__link relative">
                  <LocaleLink
                    to="/play"
                    className="flex items-center py-3 xs:py-1"
                    onMouseOver={() => this.setHoverSource(`play`, true)}
                    onFocus={() => this.setHoverSource(`play`, true)}
                    onMouseLeave={() =>
                      this.setState({
                        exploreHoverSecondary: false,
                        exploreHoverSource: null
                      })
                    }
                    onBlur={() =>
                      this.setState({
                        exploreHoverSecondary: false,
                        exploreHoverSource: null
                      })
                    }
                  >
                    <span className="index-page__explore__link__text transition-opacity f2">
                      Play
                    </span>
                    <span className="index-page__explore__link__arrow transition-opacity-transform ml-4 pt-1 f2">
                      →
                    </span>
                  </LocaleLink>
                </li>
              </ul>
            </div>
          </section>

          <Newsletter />

          <GetInTouch />

          <Footer />
        </Layout>
      </>
    );
  }
}

//

const IndexPage = ({ data, location }) => {
  const appContext = useContext(AppContext);
  const documentContext = useContext(DocumentContext);
  const { frontmatter } = data.markdownRemark;
  const projects = [];

  data.allMarkdownRemark.edges.forEach(({ node }) => {
    const { language } = node.frontmatter;

    if (appContext.locale.locale === language) {
      projects.push(node);
    }
  });

  return (
    <IndexPageComponent
      appContext={appContext}
      documentContext={documentContext}
      frontmatter={frontmatter}
      location={location}
      projects={projects}
    />
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title

        bannerImage {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        bannerVideo

        bannerImageMobile {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        bannerVideoMobile

        bannerImageAlt
        bannerLinkText
        bannerLinkTarget
        contactHover
        servicesHover
        studioHover
        workHover
        productionHover
        newsHover
        nowThemHover
        playHover
        seoDescription
        seoKeywords
      }
    }

    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "project-page" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }

          frontmatter {
            title
            language
            tagline
            isPublished
            priority
            showOnHome

            backgroundImage {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }

            backgroundImageAlt
            backgroundImageFontColor
          }
        }
      }
    }
  }
`;
