/* eslint-disable react/prop-types */

import React, { Component, createRef, useContext } from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import Img from "gatsby-image";
import Plx from "react-plx";
import { DocumentContext } from "~context/DocumentContext";
import LocaleLink from "~components/LocaleLink";

class ProjectBlockComponent extends Component {
  blockRef = createRef();

  //

  render() {
    const {
      project,
      atTop,
      className,
      documentContext,
      fullHeight,
      hovered,
      onMouseEnter,
      onMouseLeave,
      opacity,
      tagline
    } = this.props;
    const { device } = documentContext;

    let localeLink = project.fields.slug;

    if (localeLink.includes(`.`)) {
      [localeLink] = project.fields.slug.split(`.`);
    }

    let taglineOpacity = 0;

    if (this.blockRef.current) {
      const blockClientRect = this.blockRef.current.getBoundingClientRect();
      const { top } = blockClientRect;
      const relativeOffset = top - documentContext.windowHeight / 2;

      if (relativeOffset < 0) {
        taglineOpacity = parseFloat(
          0 - relativeOffset / (documentContext.windowHeight / 2)
        ).toFixed(2);

        if (taglineOpacity < 0) {
          taglineOpacity = 0;
        } else if (taglineOpacity > 1) {
          taglineOpacity = 1;
        }
      }
    }

    //

    return (
      <LocaleLink
        key={project.fields.slug}
        to={localeLink}
        className={`project-block ${atTop ? `top` : ``} ${
          fullHeight ? `project-block--banner` : `wrapped`
        } ${className} w-full relative z-10 block overflow-hidden cursor-default`}
      >
        <div
          className="project-block__link w-full absolute right-0 bottom-0 left-0 z-20 pb-8 xs:pb-8 cursor-pointer"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <div className="grid">
            <h3 className="grid-end-24 flex justify-end">
              <span
                className={`mr-3 b2 feijoa-ot text-${project.frontmatter
                  .backgroundImageFontColor || `white`}`}
                style={{
                  opacity
                }}
              >
                {project.frontmatter.title}
              </span>

              <span className="project-link-arrow transition-opacity-transform pointer-events-none b1 text-white">
                →
              </span>
            </h3>
          </div>
        </div>

        {tagline && (
          <>
            <section
              className={`project-block__view ${
                hovered ? `animation-appear` : `opacity-0`
              } w-full h-full absolute top-0 right-0 bottom-0 left-0 z-20 flex items-center justify-center pointer-events-none`}
            >
              <h3 className="f4 text-white">VIEW PROJECT →</h3>
            </section>

            <section
              ref={this.blockRef}
              className={`project-block__tagline ${
                hovered ? `opacity-0` : ``
              } w-full h-full absolute top-0 right-0 bottom-0 left-0 z-20 flex items-center pointer-events-none`}
              style={{
                opacity: hovered ? 0 : taglineOpacity
              }}
            >
              <div className="grid">
                <h3
                  className={`grid-end-18 ${
                    device === `desktop` ? `f1` : `f2`
                  } text-${project.frontmatter.backgroundImageFontColor}`}
                >
                  {project.frontmatter.tagline}
                </h3>
              </div>
            </section>
          </>
        )}

        <div className="project-block__gradient w-full h-full absolute top-0 right-0 bottom-0 left-0 z-10 bg-black"></div>

        {project.frontmatter.backgroundImage && (
          <Plx
            className="w-overflow h-full relative gpu"
            parallaxData={[
              {
                start: `self`,
                duration: documentContext.windowHeight * 2 || 1,
                properties: [
                  {
                    startValue: documentContext.windowHeight * -0.1 || 1,
                    endValue: documentContext.windowHeight * 0.1 || 1,
                    property: `translateY`
                  }
                ]
              }
            ]}
          >
            <Img
              className="w-full absolute transform-center"
              fluid={project.frontmatter.backgroundImage.childImageSharp.fluid}
              alt={project.frontmatter.backgroundImageAlt}
            />
          </Plx>
        )}
      </LocaleLink>
    );
  }
}

const ProjectBlock = ({
  project,
  atTop,
  className,
  fullHeight,
  hovered,
  onMouseEnter,
  onMouseLeave,
  opacity,
  tagline
}) => {
  const documentContext = useContext(DocumentContext);

  return (
    <ProjectBlockComponent
      project={project}
      atTop={atTop}
      className={className}
      documentContext={documentContext}
      fullHeight={fullHeight}
      hovered={hovered}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      opacity={opacity}
      tagline={tagline}
    />
  );
};

ProjectBlock.defaultProps = {
  atTop: false,
  className: ``,
  fullHeight: false,
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  tagline: true
};

ProjectBlock.propTypes = {
  atTop: PropTypes.bool,
  className: PropTypes.string,
  fullHeight: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  tagline: PropTypes.bool
};

export default ProjectBlock;
