/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React, { Component, useContext } from "react";
import { graphql, StaticQuery } from "gatsby";
import { AppContext } from "~context/AppContext";
import { DocumentContext } from "~context/DocumentContext";
import SectionHeading from "~components/SectionHeading";
import {
  fancyError,
  fancyLog,
  fancyWarning,
  validateEmail
} from "~utils/helpers";

class NewsletterComponent extends Component {
  state = {
    focused: ``,
    form: {
      student: false
    },
    submitting: false,
    submitted: false
  };

  //

  handleCheckbox = () => {
    const { form } = this.state;

    form.student = !form.student;

    this.setState({
      form
    });
  };

  //

  onBlur = () => {
    this.setState({
      focused: ``
    });
  };

  onChange = e => {
    const { form } = this.state;
    const { name, value } = e.currentTarget;

    if (!form[name]) {
      form[name] = {
        valid: false,
        value: ``
      };
    }

    switch (name) {
      case `email`:
        form[name].valid = validateEmail(value);

        break;

      default:
        break;
    }

    form[name].value = value;

    this.setState({
      form
    });
  };

  onFocus = e => {
    const focused = e.currentTarget.name;

    this.setState({
      focused
    });
  };

  onSubmit = e => {
    e.preventDefault();

    if (!this.validate()) {
      return false;
    }

    this.setState({
      submitting: true
    });

    const { form } = this.state;

    const mailchimpData = {
      email: form.email.value,
      list_id: `24407ebaee`,
      student: form.student
    };

    fetch(`${process.env.GATSBY_NETLIFY_FUNCTIONS}/mailchimp`, {
      body: JSON.stringify(mailchimpData),
      method: `POST`
    })
      .then(() => {
        fancyLog(`Mailchimp Complete`);

        setTimeout(() => {
          this.setState({
            submitting: false,
            submitted: true
          });
        }, 300);
      })
      .catch(error => {
        fancyError(error);
      });

    return false;
  };

  validate = () => {
    const { form } = this.state;

    let valid = true;

    Object.keys(form).forEach(inputKey => {
      if (!valid) {
        return;
      }

      if (form[inputKey].valid === false) {
        fancyWarning(`Invalid form value: ${inputKey}`);

        valid = false;
      }
    });

    return valid;
  };

  //

  render() {
    const { allDataYaml } = this.props;
    const { focused, form, submitted, submitting } = this.state;

    let newsletterContent = ``;
    let newsletterHeading = ``;
    let newsletterSubheading = ``;

    allDataYaml.edges.forEach(edge => {
      if (!edge.node || !edge.node.newsletter) {
        return;
      }

      edge.node.newsletter.forEach(newsletterItem => {
        if (newsletterItem.content) {
          newsletterContent = newsletterItem.content;
        }

        if (newsletterItem.heading) {
          newsletterHeading = newsletterItem.heading;
        }

        if (newsletterItem.subheading) {
          newsletterSubheading = newsletterItem.subheading;
        }
      });
    });

    return (
      <div className="newsletter w-full relative xs:pt-6 pb-32 xs:pb-24 bg-brown text-black">
        <SectionHeading text={newsletterSubheading} />

        <form className="relative grid" onSubmit={this.onSubmit}>
          <h2 className="grid-end-13 grid-start-4 md:grid-end-20 sm:grid-end-22 sm:grid-start-2 mt-8 f1">
            {newsletterHeading}
          </h2>

          <h3 className="grid-end-8 grid-start-4 md:grid-end-10 sm:grid-end-22 sm:grid-start-2 mt-10 xs:mt-12 b2">
            {newsletterContent}
          </h3>

          <label
            htmlFor="email"
            className={`newsletter__email ${
              focused === `email` ? `focused` : ``
            } ${
              submitting || submitted ? `opacity-25 pointer-events-none` : ``
            } grid-end-13 grid-start-4 md:grid-end-20 sm:grid-end-22 sm:grid-start-2 relative mt-16 xs:mt-8`}
          >
            <span className="transition-opacity-transform absolute top-0 left-0 z-10 p-2 block b2">
              Enter your email
            </span>

            <input
              className="w-full relative block mt-4 py-4 px-2 f2"
              name="email"
              placeholder={focused === `email` ? `` : `Enter your email`}
              readOnly={submitted || submitting}
              type="email"
              onBlur={this.onBlur}
              onChange={this.onChange}
              onFocus={this.onFocus}
            />
          </label>

          {form.email && form.email.valid && (
            <div className="animation-appear-down grid-end-13 grid-start-4 md:grid-end-20 sm:grid-end-22 sm:grid-start-2 relative flex justify-between mt-2">
              <label
                htmlFor="student"
                className="grid-end-13 grid-start-4 md:grid-end-20 sm:grid-end-22 sm:grid-start-2 mt-4 flex cursor-pointer"
              >
                <input
                  id="student"
                  className="h-4 relative block mr-2 test"
                  name="student"
                  type="checkbox"
                />

                <span className="relative b2">Are you a student?</span>
              </label>

              <input
                className="py-4 cursor-pointer f4 hover-underline"
                type="submit"
                value={`${submitted ? `Submitted` : `Submit`}`}
              />
            </div>
          )}
        </form>
      </div>
    );
  }
}

const query = graphql`
  query Newsletter {
    allDataYaml {
      edges {
        node {
          newsletter {
            content
            heading
            subheading
          }
        }
      }
    }
  }
`;

const Newsletter = () => {
  const appContext = useContext(AppContext);
  const documentContext = useContext(DocumentContext);

  return (
    <StaticQuery
      query={query}
      render={({ allDataYaml }) => (
        <NewsletterComponent
          appContext={appContext}
          documentContext={documentContext}
          allDataYaml={allDataYaml}
        />
      )}
    />
  );
};

export default Newsletter;
